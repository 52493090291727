import Home from '../pages/Home/route'
import Tutorials from '../pages/Tutorials/route'
import Guides from '../pages/Guides/route'
import Guide from '../pages/Guide/route'
import Series from '../pages/Series/route'
import Topic from '../pages/Topic/route'
import TopicType from '../pages/Topic/Type/route'
import Post from '../pages/Post/route'

const MARKETING_SITE_ROUTES = {
  home: Home.path,

  tutorials: Tutorials.path,

  'guides-index': Guides.path,
  'guides-view': Guide.path,

  'series-view': Series.path,

  'topics-view': Topic.path,

  'topics-view-types-view': TopicType.path,

  'posts-view': Post.path,
}

export default MARKETING_SITE_ROUTES
