/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsLoggedIn
// ====================================================

export interface IsLoggedIn_session {
  __typename: "Session";
  id: string;
  type: UserType;
  isAdmin: boolean;
}

export interface IsLoggedIn {
  /**
   * Get the session of the current user
   */
  session: IsLoggedIn_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteFooterComponentCurrencies
// ====================================================

export interface SiteFooterComponentCurrencies_currencies_nodes {
  __typename: "Currency";
  code: CurrencyCode;
  name: string;
}

export interface SiteFooterComponentCurrencies_currencies {
  __typename: "CurrencyList";
  nodes: SiteFooterComponentCurrencies_currencies_nodes[];
}

export interface SiteFooterComponentCurrencies {
  /**
   * Get all currencies
   */
  currencies: SiteFooterComponentCurrencies_currencies | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SiteHeaderComponent_Session
// ====================================================

export interface SiteHeaderComponent_Session_session_avatar {
  __typename: "Image";
  url: string;
}

export interface SiteHeaderComponent_Session_session_membership {
  __typename: "UserMembership";
  id: string;
}

export interface SiteHeaderComponent_Session_session {
  __typename: "Session";
  id: string;
  username: string;
  name: string | null;
  email: string;
  avatar: SiteHeaderComponent_Session_session_avatar | null;
  membership: SiteHeaderComponent_Session_session_membership | null;
}

export interface SiteHeaderComponent_Session {
  /**
   * Get the session of the current user
   */
  session: SiteHeaderComponent_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseTrackIdentity
// ====================================================

export interface UseTrackIdentity_session {
  __typename: "Session";
  id: string;
}

export interface UseTrackIdentity {
  /**
   * Get the session of the current user
   */
  session: UseTrackIdentity_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseEvents_LoggedIn_Session
// ====================================================

export interface UseEvents_LoggedIn_Session_session_team {
  __typename: "Team";
  id: string;
}

export interface UseEvents_LoggedIn_Session_session_membership_plan_product {
  __typename: "MembershipProduct";
  id: string;
  slug: string;
}

export interface UseEvents_LoggedIn_Session_session_membership_plan {
  __typename: "UserMembershipPlan";
  product: UseEvents_LoggedIn_Session_session_membership_plan_product;
}

export interface UseEvents_LoggedIn_Session_session_membership {
  __typename: "UserMembership";
  id: string;
  status: MembershipStatus;
  plan: UseEvents_LoggedIn_Session_session_membership_plan | null;
}

export interface UseEvents_LoggedIn_Session_session {
  __typename: "Session";
  id: string;
  name: string | null;
  email: string;
  type: UserType;
  team: UseEvents_LoggedIn_Session_session_team | null;
  membership: UseEvents_LoggedIn_Session_session_membership | null;
}

export interface UseEvents_LoggedIn_Session {
  /**
   * Get the session of the current user
   */
  session: UseEvents_LoggedIn_Session_session | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GuideLeadCreate
// ====================================================

export interface GuideLeadCreate_leadCreate {
  __typename: "Lead";
  email: string;
}

export interface GuideLeadCreate {
  /**
   * Create a lead
   */
  leadCreate: GuideLeadCreate_leadCreate | null;
}

export interface GuideLeadCreateVariables {
  email: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidePages
// ====================================================

export interface GuidePages_libraryGuides_nodes {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
}

export interface GuidePages_libraryGuides {
  __typename: "LibraryGuideList";
  nodes: GuidePages_libraryGuides_nodes[];
}

export interface GuidePages {
  /**
   * Library guides
   */
  libraryGuides: GuidePages_libraryGuides | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Guide
// ====================================================

export interface Guide_libraryGuide_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Guide_libraryGuide_posts_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Guide_libraryGuide_posts_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Guide_libraryGuide_posts_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Guide_libraryGuide_posts_LibraryPostArticle_topics_nodes[];
}

export interface Guide_libraryGuide_posts_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Guide_libraryGuide_posts_LibraryPostArticle_image;
  topics: Guide_libraryGuide_posts_LibraryPostArticle_topics | null;
  description: string;
}

export interface Guide_libraryGuide_posts_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Guide_libraryGuide_posts_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Guide_libraryGuide_posts_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Guide_libraryGuide_posts_LibraryPostVideo_topics_nodes[];
}

export interface Guide_libraryGuide_posts_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Guide_libraryGuide_posts_LibraryPostVideo_image;
  topics: Guide_libraryGuide_posts_LibraryPostVideo_topics | null;
  description: string;
}

export type Guide_libraryGuide_posts = Guide_libraryGuide_posts_LibraryPostArticle | Guide_libraryGuide_posts_LibraryPostVideo;

export interface Guide_libraryGuide {
  __typename: "LibraryGuide";
  mailchimpTag: string;
  seoDescription: string;
  description: string;
  downloadUrl: string;
  id: string;
  color: LibraryGuideColor;
  image: Guide_libraryGuide_image;
  posts: Guide_libraryGuide_posts[];
  overview: string[];
  slug: string;
  title: string;
}

export interface Guide {
  /**
   * Library guide by slug
   */
  libraryGuide: Guide_libraryGuide | null;
}

export interface GuideVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GuidesHome
// ====================================================

export interface GuidesHome_libraryGuides_nodes_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface GuidesHome_libraryGuides_nodes {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
  title: string;
  image: GuidesHome_libraryGuides_nodes_image;
}

export interface GuidesHome_libraryGuides {
  __typename: "LibraryGuideList";
  nodes: GuidesHome_libraryGuides_nodes[];
}

export interface GuidesHome {
  /**
   * Library guides
   */
  libraryGuides: GuidesHome_libraryGuides | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Home
// ====================================================

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_imageSquare {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_image;
  imageSquare: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_imageSquare;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle_topics | null;
  description: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo_topics | null;
  description: string;
}

export type Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1 = Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostArticle | Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1_LibraryPostVideo;

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle1 {
  __typename: "LibraryHomeBlockArticle1";
  article1: Home_libraryHomeBlocks_LibraryHomeBlockArticle1_article1[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle_topics | null;
  description: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo_topics | null;
  description: string;
}

export type Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2 = Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostArticle | Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2_LibraryPostVideo;

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle2 {
  __typename: "LibraryHomeBlockArticle2";
  article2: Home_libraryHomeBlocks_LibraryHomeBlockArticle2_article2[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle_topics | null;
  description: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo_topics | null;
  description: string;
}

export type Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3 = Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostArticle | Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3_LibraryPostVideo;

export interface Home_libraryHomeBlocks_LibraryHomeBlockArticle3 {
  __typename: "LibraryHomeBlockArticle3";
  article3: Home_libraryHomeBlocks_LibraryHomeBlockArticle3_article3[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockGuide1_items_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockGuide1_items {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
  title: string;
  image: Home_libraryHomeBlocks_LibraryHomeBlockGuide1_items_image;
  description: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockGuide1 {
  __typename: "LibraryHomeBlockGuide1";
  items: Home_libraryHomeBlocks_LibraryHomeBlockGuide1_items[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo_image;
  topics: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes = Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostArticle | Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes_LibraryPostVideo;

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts {
  __typename: "LibraryPostList";
  nodes: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts_nodes[];
  totalCount: number;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items {
  __typename: "LibraryCollection";
  id: string;
  slug: string;
  title: string;
  description: string;
  color: LibraryGuideColor;
  type: LibraryCollectionType;
  posts: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items_posts | null;
}

export interface Home_libraryHomeBlocks_LibraryHomeBlockCollection1 {
  __typename: "LibraryHomeBlockCollection1";
  items: Home_libraryHomeBlocks_LibraryHomeBlockCollection1_items[];
}

export type Home_libraryHomeBlocks = Home_libraryHomeBlocks_LibraryHomeBlockArticle1 | Home_libraryHomeBlocks_LibraryHomeBlockArticle2 | Home_libraryHomeBlocks_LibraryHomeBlockArticle3 | Home_libraryHomeBlocks_LibraryHomeBlockGuide1 | Home_libraryHomeBlocks_LibraryHomeBlockCollection1;

export interface Home {
  /**
   * Libray home blocks
   */
  libraryHomeBlocks: Home_libraryHomeBlocks[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: LibraryNewsletterLeadCreate
// ====================================================

export interface LibraryNewsletterLeadCreate_leadCreate {
  __typename: "Lead";
  email: string;
}

export interface LibraryNewsletterLeadCreate {
  /**
   * Create a lead
   */
  leadCreate: LibraryNewsletterLeadCreate_leadCreate | null;
}

export interface LibraryNewsletterLeadCreateVariables {
  email: string;
  tag: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostPages
// ====================================================

export interface PostPages_libraryPosts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
}

export interface PostPages_libraryPosts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
}

export type PostPages_libraryPosts_nodes = PostPages_libraryPosts_nodes_LibraryPostArticle | PostPages_libraryPosts_nodes_LibraryPostVideo;

export interface PostPages_libraryPosts {
  __typename: "LibraryPostList";
  nodes: PostPages_libraryPosts_nodes[];
}

export interface PostPages {
  /**
   * Library posts
   */
  libraryPosts: PostPages_libraryPosts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Post
// ====================================================

export interface Post_libraryPost_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  width: number;
  height: number;
  blurhash: string;
}

export interface Post_libraryPost_LibraryPostArticle_imageCropped {
  __typename: "Image";
  url: string;
  width: number;
  height: number;
  blurhash: string;
}

export interface Post_libraryPost_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostArticle_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_guides_nodes_posts_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
}

export interface Post_libraryPost_LibraryPostArticle_guides_nodes_posts_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
}

export type Post_libraryPost_LibraryPostArticle_guides_nodes_posts = Post_libraryPost_LibraryPostArticle_guides_nodes_posts_LibraryPostArticle | Post_libraryPost_LibraryPostArticle_guides_nodes_posts_LibraryPostVideo;

export interface Post_libraryPost_LibraryPostArticle_guides_nodes {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
  title: string;
  posts: Post_libraryPost_LibraryPostArticle_guides_nodes_posts[];
}

export interface Post_libraryPost_LibraryPostArticle_guides {
  __typename: "LibraryGuideList";
  nodes: Post_libraryPost_LibraryPostArticle_guides_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_author {
  __typename: "LibraryAuthor";
  name: string;
  url: string | null;
  description: string | null;
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle_image;
  topics: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo_image;
  topics: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes = Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostArticle | Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes_LibraryPostVideo;

export interface Post_libraryPost_LibraryPostArticle_collections_nodes_posts {
  __typename: "LibraryPostList";
  nodes: Post_libraryPost_LibraryPostArticle_collections_nodes_posts_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_collections_nodes {
  __typename: "LibraryCollection";
  id: string;
  slug: string;
  title: string;
  posts: Post_libraryPost_LibraryPostArticle_collections_nodes_posts | null;
}

export interface Post_libraryPost_LibraryPostArticle_collections {
  __typename: "LibraryCollectionList";
  nodes: Post_libraryPost_LibraryPostArticle_collections_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle_image;
  topics: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo_image;
  topics: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Post_libraryPost_LibraryPostArticle_relatedPosts_nodes = Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostArticle | Post_libraryPost_LibraryPostArticle_relatedPosts_nodes_LibraryPostVideo;

export interface Post_libraryPost_LibraryPostArticle_relatedPosts {
  __typename: "LibraryPostList";
  nodes: Post_libraryPost_LibraryPostArticle_relatedPosts_nodes[];
}

export interface Post_libraryPost_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  title: string;
  body: string;
  image: Post_libraryPost_LibraryPostArticle_image;
  imageCropped: Post_libraryPost_LibraryPostArticle_imageCropped;
  topics: Post_libraryPost_LibraryPostArticle_topics | null;
  guides: Post_libraryPost_LibraryPostArticle_guides | null;
  illustratorName: string | null;
  illustratorUrl: string | null;
  publishedAt: any;
  intro: string;
  description: string;
  author: Post_libraryPost_LibraryPostArticle_author;
  collections: Post_libraryPost_LibraryPostArticle_collections | null;
  relatedPosts: Post_libraryPost_LibraryPostArticle_relatedPosts | null;
}

export interface Post_libraryPost_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
  width: number;
  height: number;
}

export interface Post_libraryPost_LibraryPostVideo_transcripts {
  __typename: "VideoTranscript";
  englishName: string;
  language: string;
  nativeName: string;
  text: string;
}

export interface Post_libraryPost_LibraryPostVideo_guides_nodes_posts_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
}

export interface Post_libraryPost_LibraryPostVideo_guides_nodes_posts_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
}

export type Post_libraryPost_LibraryPostVideo_guides_nodes_posts = Post_libraryPost_LibraryPostVideo_guides_nodes_posts_LibraryPostArticle | Post_libraryPost_LibraryPostVideo_guides_nodes_posts_LibraryPostVideo;

export interface Post_libraryPost_LibraryPostVideo_guides_nodes {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
  title: string;
  posts: Post_libraryPost_LibraryPostVideo_guides_nodes_posts[];
}

export interface Post_libraryPost_LibraryPostVideo_guides {
  __typename: "LibraryGuideList";
  nodes: Post_libraryPost_LibraryPostVideo_guides_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_author {
  __typename: "LibraryAuthor";
  name: string;
  url: string | null;
  description: string | null;
}

export interface Post_libraryPost_LibraryPostVideo_next_image {
  __typename: "Image";
  url: string;
}

export interface Post_libraryPost_LibraryPostVideo_next_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_next_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_next_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_next {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Post_libraryPost_LibraryPostVideo_next_image;
  topics: Post_libraryPost_LibraryPostVideo_next_topics | null;
  description: string;
}

export interface Post_libraryPost_LibraryPostVideo_previous_image {
  __typename: "Image";
  url: string;
}

export interface Post_libraryPost_LibraryPostVideo_previous_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_previous_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_previous_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_previous {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Post_libraryPost_LibraryPostVideo_previous_image;
  topics: Post_libraryPost_LibraryPostVideo_previous_topics | null;
  description: string;
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle_image;
  topics: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo_image;
  topics: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes = Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostArticle | Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes_LibraryPostVideo;

export interface Post_libraryPost_LibraryPostVideo_collections_nodes_posts {
  __typename: "LibraryPostList";
  nodes: Post_libraryPost_LibraryPostVideo_collections_nodes_posts_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_collections_nodes {
  __typename: "LibraryCollection";
  id: string;
  slug: string;
  title: string;
  posts: Post_libraryPost_LibraryPostVideo_collections_nodes_posts | null;
}

export interface Post_libraryPost_LibraryPostVideo_collections {
  __typename: "LibraryCollectionList";
  nodes: Post_libraryPost_LibraryPostVideo_collections_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle_image;
  topics: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo_image;
  topics: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Post_libraryPost_LibraryPostVideo_relatedPosts_nodes = Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostArticle | Post_libraryPost_LibraryPostVideo_relatedPosts_nodes_LibraryPostVideo;

export interface Post_libraryPost_LibraryPostVideo_relatedPosts {
  __typename: "LibraryPostList";
  nodes: Post_libraryPost_LibraryPostVideo_relatedPosts_nodes[];
}

export interface Post_libraryPost_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  title: string;
  image: Post_libraryPost_LibraryPostVideo_image;
  publishedAt: any;
  intro: string;
  description: string;
  wistiaId: string;
  transcripts: Post_libraryPost_LibraryPostVideo_transcripts[] | null;
  guides: Post_libraryPost_LibraryPostVideo_guides | null;
  topics: Post_libraryPost_LibraryPostVideo_topics | null;
  demoUrl: string | null;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  author: Post_libraryPost_LibraryPostVideo_author;
  next: Post_libraryPost_LibraryPostVideo_next | null;
  previous: Post_libraryPost_LibraryPostVideo_previous | null;
  collections: Post_libraryPost_LibraryPostVideo_collections | null;
  relatedPosts: Post_libraryPost_LibraryPostVideo_relatedPosts | null;
}

export type Post_libraryPost = Post_libraryPost_LibraryPostArticle | Post_libraryPost_LibraryPostVideo;

export interface Post {
  /**
   * Library post
   */
  libraryPost: Post_libraryPost | null;
}

export interface PostVariables {
  slug: string;
  status?: LibraryPostStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Page
// ====================================================

export interface Page_libraryTopics {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Page_productCourses_nodes {
  __typename: "ProductCourse";
  id: string;
  subjects: ProductCourseSubject[];
}

export interface Page_productCourses {
  __typename: "ProductCourseList";
  nodes: Page_productCourses_nodes[];
}

export interface Page {
  /**
   * Library topics
   */
  libraryTopics: Page_libraryTopics[];
  /**
   * Get all product courses
   */
  productCourses: Page_productCourses | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SeriesPages
// ====================================================

export interface SeriesPages_libraryCollections_nodes {
  __typename: "LibraryCollection";
  id: string;
  slug: string;
}

export interface SeriesPages_libraryCollections {
  __typename: "LibraryCollectionList";
  nodes: SeriesPages_libraryCollections_nodes[];
}

export interface SeriesPages {
  /**
   * Library collections
   */
  libraryCollections: SeriesPages_libraryCollections | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Collection
// ====================================================

export interface Collection_libraryCollection_posts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Collection_libraryCollection_posts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Collection_libraryCollection_posts_nodes_LibraryPostArticle_image;
  topics: Collection_libraryCollection_posts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Collection_libraryCollection_posts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Collection_libraryCollection_posts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Collection_libraryCollection_posts_nodes_LibraryPostVideo_image;
  topics: Collection_libraryCollection_posts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Collection_libraryCollection_posts_nodes = Collection_libraryCollection_posts_nodes_LibraryPostArticle | Collection_libraryCollection_posts_nodes_LibraryPostVideo;

export interface Collection_libraryCollection_posts {
  __typename: "LibraryPostList";
  nodes: Collection_libraryCollection_posts_nodes[];
}

export interface Collection_libraryCollection {
  __typename: "LibraryCollection";
  id: string;
  title: string;
  description: string;
  slug: string;
  color: LibraryGuideColor;
  type: LibraryCollectionType;
  posts: Collection_libraryCollection_posts | null;
}

export interface Collection {
  /**
   * Library collection
   */
  libraryCollection: Collection_libraryCollection | null;
}

export interface CollectionVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopicPages
// ====================================================

export interface TopicPages_libraryTopics {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
}

export interface TopicPages {
  /**
   * Library topics
   */
  libraryTopics: TopicPages_libraryTopics[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Topic
// ====================================================

export interface Topic_libraryTopic {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
  color: LibraryGuideColor;
}

export interface Topic {
  /**
   * Library topic by slug
   */
  libraryTopic: Topic_libraryTopic | null;
}

export interface TopicVariables {
  slug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopicPosts
// ====================================================

export interface TopicPosts_libraryPosts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: TopicPosts_libraryPosts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: TopicPosts_libraryPosts_nodes_LibraryPostArticle_image;
  topics: TopicPosts_libraryPosts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: TopicPosts_libraryPosts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface TopicPosts_libraryPosts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: TopicPosts_libraryPosts_nodes_LibraryPostVideo_image;
  topics: TopicPosts_libraryPosts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type TopicPosts_libraryPosts_nodes = TopicPosts_libraryPosts_nodes_LibraryPostArticle | TopicPosts_libraryPosts_nodes_LibraryPostVideo;

export interface TopicPosts_libraryPosts_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  totalCount: number;
}

export interface TopicPosts_libraryPosts {
  __typename: "LibraryPostList";
  nodes: TopicPosts_libraryPosts_nodes[];
  totalCount: number;
  pageInfo: TopicPosts_libraryPosts_pageInfo;
}

export interface TopicPosts {
  /**
   * Library posts
   */
  libraryPosts: TopicPosts_libraryPosts | null;
}

export interface TopicPostsVariables {
  topicSlug?: string | null;
  page?: number | null;
  perPage?: number | null;
  type?: LibraryPostType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Tutorials
// ====================================================

export interface Tutorials_libraryPosts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface Tutorials_libraryPosts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Tutorials_libraryPosts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: Tutorials_libraryPosts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface Tutorials_libraryPosts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: Tutorials_libraryPosts_nodes_LibraryPostArticle_image;
  topics: Tutorials_libraryPosts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface Tutorials_libraryPosts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface Tutorials_libraryPosts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface Tutorials_libraryPosts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: Tutorials_libraryPosts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface Tutorials_libraryPosts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: Tutorials_libraryPosts_nodes_LibraryPostVideo_image;
  topics: Tutorials_libraryPosts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type Tutorials_libraryPosts_nodes = Tutorials_libraryPosts_nodes_LibraryPostArticle | Tutorials_libraryPosts_nodes_LibraryPostVideo;

export interface Tutorials_libraryPosts_pageInfo {
  __typename: "PageInfo";
  hasNextPage: boolean;
  totalCount: number;
}

export interface Tutorials_libraryPosts {
  __typename: "LibraryPostList";
  nodes: Tutorials_libraryPosts_nodes[];
  totalCount: number;
  pageInfo: Tutorials_libraryPosts_pageInfo;
}

export interface Tutorials {
  /**
   * Library posts
   */
  libraryPosts: Tutorials_libraryPosts | null;
}

export interface TutorialsVariables {
  page?: number | null;
  perPage?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AuthSession
// ====================================================

export interface AuthSession {
  __typename: "Session";
  id: string;
  type: UserType;
  isAdmin: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SessionSiteHeaderComponent
// ====================================================

export interface SessionSiteHeaderComponent_avatar {
  __typename: "Image";
  url: string;
}

export interface SessionSiteHeaderComponent_membership {
  __typename: "UserMembership";
  id: string;
}

export interface SessionSiteHeaderComponent {
  __typename: "Session";
  id: string;
  username: string;
  name: string | null;
  email: string;
  avatar: SessionSiteHeaderComponent_avatar | null;
  membership: SessionSiteHeaderComponent_membership | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryCollectionBlockComponent
// ====================================================

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle_topics {
  __typename: "LibraryTopicList";
  nodes: LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle_topics_nodes[];
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle_image;
  topics: LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle_topics | null;
  description: string;
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo_image {
  __typename: "Image";
  url: string;
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo_topics {
  __typename: "LibraryTopicList";
  nodes: LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo_topics_nodes[];
}

export interface LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo_image;
  topics: LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo_topics | null;
  description: string;
}

export type LibraryCollectionBlockComponent_posts_nodes = LibraryCollectionBlockComponent_posts_nodes_LibraryPostArticle | LibraryCollectionBlockComponent_posts_nodes_LibraryPostVideo;

export interface LibraryCollectionBlockComponent_posts {
  __typename: "LibraryPostList";
  nodes: LibraryCollectionBlockComponent_posts_nodes[];
  totalCount: number;
}

export interface LibraryCollectionBlockComponent {
  __typename: "LibraryCollection";
  id: string;
  slug: string;
  title: string;
  description: string;
  color: LibraryGuideColor;
  type: LibraryCollectionType;
  posts: LibraryCollectionBlockComponent_posts | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryGuideBlockComponent
// ====================================================

export interface LibraryGuideBlockComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryGuideBlockComponent {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
  title: string;
  image: LibraryGuideBlockComponent_image;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryGuideCardComponent
// ====================================================

export interface LibraryGuideCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryGuideCardComponent {
  __typename: "LibraryGuide";
  id: string;
  slug: string;
  title: string;
  image: LibraryGuideCardComponent_image;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryPostArticleBlockComponent
// ====================================================

export interface LibraryPostArticleBlockComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryPostArticleBlockComponent_imageSquare {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryPostArticleBlockComponent_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface LibraryPostArticleBlockComponent_topics {
  __typename: "LibraryTopicList";
  nodes: LibraryPostArticleBlockComponent_topics_nodes[];
}

export interface LibraryPostArticleBlockComponent {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: LibraryPostArticleBlockComponent_image;
  imageSquare: LibraryPostArticleBlockComponent_imageSquare;
  topics: LibraryPostArticleBlockComponent_topics | null;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryPostArticleCardComponent
// ====================================================

export interface LibraryPostArticleCardComponent_image {
  __typename: "Image";
  url: string;
  blurhash: string;
}

export interface LibraryPostArticleCardComponent_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface LibraryPostArticleCardComponent_topics {
  __typename: "LibraryTopicList";
  nodes: LibraryPostArticleCardComponent_topics_nodes[];
}

export interface LibraryPostArticleCardComponent {
  __typename: "LibraryPostArticle";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: LibraryPostArticleCardComponent_image;
  topics: LibraryPostArticleCardComponent_topics | null;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryPostVideoBlockComponent
// ====================================================

export interface LibraryPostVideoBlockComponent_image {
  __typename: "Image";
  url: string;
}

export interface LibraryPostVideoBlockComponent_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface LibraryPostVideoBlockComponent_topics {
  __typename: "LibraryTopicList";
  nodes: LibraryPostVideoBlockComponent_topics_nodes[];
}

export interface LibraryPostVideoBlockComponent {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  image: LibraryPostVideoBlockComponent_image;
  topics: LibraryPostVideoBlockComponent_topics | null;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: LibraryPostVideoCardComponent
// ====================================================

export interface LibraryPostVideoCardComponent_image {
  __typename: "Image";
  url: string;
}

export interface LibraryPostVideoCardComponent_topics_nodes {
  __typename: "LibraryTopic";
  id: string;
  slug: string;
  title: string;
}

export interface LibraryPostVideoCardComponent_topics {
  __typename: "LibraryTopicList";
  nodes: LibraryPostVideoCardComponent_topics_nodes[];
}

export interface LibraryPostVideoCardComponent {
  __typename: "LibraryPostVideo";
  id: string;
  slug: string;
  type: LibraryPostType;
  title: string;
  duration: number;
  difficultyLevel: VideoDifficultyLevel;
  image: LibraryPostVideoCardComponent_image;
  topics: LibraryPostVideoCardComponent_topics | null;
  description: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Currency Code
 * 
 * The available currency codes
 */
export enum CurrencyCode {
  AUD = "AUD",
  CAD = "CAD",
  CHF = "CHF",
  DKK = "DKK",
  EUR = "EUR",
  GBP = "GBP",
  HKD = "HKD",
  JPY = "JPY",
  NOK = "NOK",
  NZD = "NZD",
  SEK = "SEK",
  SGD = "SGD",
  USD = "USD",
}

/**
 * Library collection type
 */
export enum LibraryCollectionType {
  NORMAL = "NORMAL",
  SERIES = "SERIES",
}

/**
 * Library Guide color
 */
export enum LibraryGuideColor {
  BLUE_01 = "BLUE_01",
  GREEN_01 = "GREEN_01",
  PINK_01 = "PINK_01",
  RED_01 = "RED_01",
  YELLOW_01 = "YELLOW_01",
}

/**
 * Library Post status
 */
export enum LibraryPostStatus {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
}

/**
 * Library item type
 */
export enum LibraryPostType {
  ADVICE = "ADVICE",
  ARTICLE = "ARTICLE",
  INTERVIEW = "INTERVIEW",
  TUTORIAL = "TUTORIAL",
}

/**
 * Membership status
 */
export enum MembershipStatus {
  ACTIVE = "ACTIVE",
  DEFAULTED = "DEFAULTED",
  INACTIVE = "INACTIVE",
  TRIALING = "TRIALING",
}

/**
 * Product course subject
 */
export enum ProductCourseSubject {
  CODE = "CODE",
  DESIGN = "DESIGN",
  PROJECT_MANAGEMENT = "PROJECT_MANAGEMENT",
}

/**
 * User Type
 */
export enum UserType {
  FREE = "FREE",
  MEMBER = "MEMBER",
  STUDENT = "STUDENT",
  TEAM_MEMBER = "TEAM_MEMBER",
}

/**
 * Video difficulty level
 */
export enum VideoDifficultyLevel {
  ADVANCED = "ADVANCED",
  BEGINNER = "BEGINNER",
  INTERMEDIATE = "INTERMEDIATE",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
