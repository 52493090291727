import { SiteFooter } from '@superhi/design'

import { API } from '../../../../api'
import { buildMarketingSiteURL } from '../../../../routing'
import { buildPath } from '../../../../routing/utils'

export const ITEMS: React.ComponentProps<typeof SiteFooter>['items'] = [
  {
    display: 'Learn',
    items: [
      {
        display: 'Code',
        href: buildMarketingSiteURL('courses-index', {
          query: {
            subject: API.ProductCourseSubject.CODE,
          },
        }),
        isExternal: true,
      },
      {
        display: 'Design',
        href: buildMarketingSiteURL('courses-index', {
          query: {
            subject: API.ProductCourseSubject.DESIGN,
          },
        }),
        isExternal: true,
      },
      {
        display: 'Project Management',
        href: buildMarketingSiteURL('courses-index', {
          query: {
            subject: API.ProductCourseSubject.PROJECT_MANAGEMENT,
          },
        }),
        isExternal: true,
      },
      {
        display: 'Plan, Design + Code Your First Website',
        href: buildMarketingSiteURL('courses-view', {
          params: {
            courseSlug: 'plan-design-and-code-your-first-website',
          },
        }),
        isExternal: true,
      },
      {
        display: 'Learning paths',
        href: buildMarketingSiteURL('collections-index'),
        isExternal: true,
      },
      {
        display: 'SuperHi for Teams',
        href: buildMarketingSiteURL('teams'),
        isExternal: true,
      },
      {
        display: 'All Courses',
        href: buildMarketingSiteURL('courses-index'),
        isExternal: true,
      },
    ],
  },
  {
    display: 'Resources',
    items: [
      {
        display: 'Library',
        href: buildPath('home'),
      },
      {
        display: 'Guides',
        href: buildPath('guides-index'),
      },
      {
        display: 'Tutorials',
        href: buildPath('tutorials'),
      },
      { display: 'Learn To Code Book', href: buildMarketingSiteURL('book'), isExternal: true },
      {
        display: 'SuperHi Code Editor',
        href: buildMarketingSiteURL('editor'),
        isExternal: true,
      },
    ],
  },
  {
    display: 'Company',
    items: [
      { display: 'About SuperHi', href: buildMarketingSiteURL('about'), isExternal: true },
      {
        display: 'Student Work',
        href: buildMarketingSiteURL('hall-of-fame-index'),
        isExternal: true,
      },
      { display: 'Press', href: buildMarketingSiteURL('press'), isExternal: true },
      { display: 'Jobs', href: buildMarketingSiteURL('jobs-index'), isExternal: true },
    ],
  },
  {
    display: 'Support',
    items: [
      { display: 'Help Center', href: buildMarketingSiteURL('help-center-index') },
      {
        display: 'Code of Conduct',
        href: buildMarketingSiteURL('code-of-conduct'),
        isExternal: true,
      },
      {
        display: 'Return Policy',
        href: buildMarketingSiteURL('return-policy'),
        isExternal: true,
      },
      { display: 'Contact Us', href: buildMarketingSiteURL('contact'), isExternal: true },
    ],
  },
]

export const SOCIAL_LINKS: React.ComponentProps<typeof SiteFooter>['socialLinks'] = [
  {
    name: 'facebook',
    href: 'https://www.facebook.com/superhidotcom',
  },
  {
    name: 'instagram',
    href: 'https://www.instagram.com/hisuperhi/',
  },
  {
    name: 'twitter',
    href: 'https://twitter.com/superhi_',
  },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/c/superhi',
  },
]

export const BOTTOM_LINKS: React.ComponentProps<typeof SiteFooter>['bottomLinks'] = [
  {
    display: 'Terms of Use',
    href: buildMarketingSiteURL('terms-of-use'),
    isExternal: true,
  },
  {
    display: 'Privacy Policy',
    href: buildMarketingSiteURL('privacy-policy'),
    isExternal: true,
  },
]
