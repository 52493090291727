import Cookies from 'js-cookie'

const name: 'auth-token' = 'auth-token'

const DOMAIN = '.superhi.com'

const get = (): string | undefined => Cookies.get(name)

const set = (token: string, exludeDomain?: boolean) => {
  if (process.env.NODE_ENV === 'development') {
    Cookies.set(name, token)
  }

  Cookies.set(name, token, { domain: exludeDomain ? undefined : DOMAIN })
}

const remove = (exludeDomain?: boolean) => {
  if (process.env.NODE_ENV === 'development') {
    Cookies.remove(name)
  }

  Cookies.remove(name, {
    domain: exludeDomain ? undefined : DOMAIN,
  })
}

export default {
  name,
  get,
  set,
  remove,
}
