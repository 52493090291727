import { GoogleAnalytics, Heap, HelpScout } from '../../../services'

export const loggedOut = () => {
  // helpscout
  HelpScout.logout()

  // heap
  Heap.resetIdentity()
  Heap.clearEventProperties()

  // google analytics
  GoogleAnalytics.identify()
}
