import { ResponsiveBreakpoint } from '@superhi/design/dist/cjs/theme/media'
import React from 'react'

import { Wrapper } from './styles'

type Props = {
  height?: Record<ResponsiveBreakpoint, number>
}

const Logo: React.FC<Props> = ({ height }) => (
  <Wrapper data-component-name="SuperLibraryLogo" $height={height}>
    <path
      d="M10.3953 21.6744C16.3721 21.6744 19.7674 19.6279 19.7674 15.9535C19.7674 12.907 17.8837 11.5581 13.3256 11.093L8.7907 10.6279C6.67442 10.4186 6 10.0233 6 9.02325C6 7.88372 7.37209 7.27907 9.93023 7.27907C13.093 7.27907 15.4651 8.25581 16.6744 9.90698L19.6512 7.4186C17.5814 5.09302 14.3256 4 10.1628 4C4.51163 4 1.30233 5.95349 1.30233 9.23256C1.30233 12.2791 3.27907 13.7674 7.30233 14.2093L12.3721 14.7674C14.3256 14.9767 15.0233 15.3953 15.0233 16.4186C15.0233 17.7209 13.6279 18.3488 10.6279 18.3488C7.25581 18.3488 4.72093 17.0233 3.34884 15.093L0 17.4419C1.69767 20.0465 5.51163 21.6744 10.3953 21.6744Z"
      fill="currentColor"
    />
    <path
      d="M27.4098 21.5349C30.1539 21.5349 32.5028 20.4884 33.5493 18.3721V21.093H37.9679V9.46512H33.4795V14.3953C33.4795 16.5349 32.1307 18.4186 29.1772 18.4186C27.1539 18.4186 25.5958 17.4419 25.5958 14.6977V9.46512H21.1074V15.7674C21.1074 19.5116 23.433 21.5349 27.4098 21.5349Z"
      fill="currentColor"
    />
    <path
      d="M41.0088 25.6279H45.4972V19.2558C46.7065 20.6279 48.7995 21.5349 51.4274 21.5349C55.7995 21.5349 58.8926 19 58.8926 15.186C58.8926 11.5116 55.9856 9.02325 51.567 9.02325C49.0088 9.02325 46.7298 9.83721 45.4274 11.4884V9.46512H41.0088V25.6279ZM49.8693 18.6512C47.1949 18.6512 45.4042 17.3256 45.4042 15.2791V15.0698C45.4042 13.1628 47.2647 11.8837 49.9391 11.8837C52.6135 11.8837 54.2181 13.1628 54.2181 15.186C54.2181 17.3256 52.5902 18.6512 49.8693 18.6512Z"
      fill="currentColor"
    />
    <path
      d="M77.1713 15.6047C77.1713 11.4884 74.0085 9.02325 68.5202 9.02325C63.2876 9.02325 59.8923 11.3488 59.8923 15.3256C59.8923 19.2326 63.1713 21.5349 68.6364 21.5349C72.3806 21.5349 75.1016 20.4419 76.7295 18.4884L73.2876 17C72.4271 18.0698 70.7527 18.7209 68.8225 18.7209C66.1946 18.7209 64.5667 17.814 64.2178 16.2558H77.1713V15.6047ZM64.1946 13.9302C64.4736 12.4186 66.0318 11.4419 68.4969 11.4419C70.9388 11.4419 72.6597 12.4651 72.7062 13.9302H64.1946Z"
      fill="currentColor"
    />
    <path
      d="M82.86 12.907V9.46512H78.9297V21.093H83.4181V17.3256C83.4181 15.9535 83.7902 14.7674 84.6507 13.9767C85.3251 13.3023 86.3949 12.907 87.8367 12.907C88.5111 12.907 89.0693 12.9767 89.6972 13.093V9.27907C89.2088 9.2093 88.86 9.16279 88.1623 9.16279C85.4646 9.16279 83.5111 10.6279 82.86 12.907Z"
      fill="currentColor"
    />
    <path d="M91.4231 21.093H107.307V17.4186H96.1208V4.58139H91.4231V21.093Z" fill="currentColor" />
    <path
      d="M108.921 7.74419H113.666V4.18605H108.921V7.74419ZM109.061 21.093H113.549V9.46512H109.061V21.093Z"
      fill="currentColor"
    />
    <path
      d="M127.063 21.5349C131.551 21.5349 134.598 18.9535 134.598 15.1628C134.598 11.5116 131.668 9.02325 127.342 9.02325C124.691 9.02325 122.528 9.86046 121.202 11.3721V4.23256H116.714V21.093H120.737L121.086 19.093C122.272 20.5814 124.389 21.5349 127.063 21.5349ZM125.551 18.6744C122.83 18.6744 121.04 17.3256 121.04 15.3023V15.0698C121.04 13.186 122.923 11.8837 125.621 11.8837C128.249 11.8837 129.923 13.1628 129.923 15.186C129.923 17.3023 128.202 18.6744 125.551 18.6744Z"
      fill="currentColor"
    />
    <path
      d="M140.215 12.907V9.46512H136.285V21.093H140.773V17.3256C140.773 15.9535 141.145 14.7674 142.006 13.9767C142.68 13.3023 143.75 12.907 145.192 12.907C145.866 12.907 146.425 12.9767 147.052 13.093V9.27907C146.564 9.2093 146.215 9.16279 145.518 9.16279C142.82 9.16279 140.866 10.6279 140.215 12.907Z"
      fill="currentColor"
    />
    <path
      d="M153.09 21.5349C156.3 21.5349 158.556 20.6047 159.463 18.9535C159.463 19.6512 159.532 20.5349 159.625 21.093H164.114C163.974 20.1628 163.904 19.093 163.904 17.4651V14.2791C163.904 10.814 161.509 9.02325 156.067 9.02325C151.37 9.02325 148.067 10.7442 147.602 13.4419H152.393C152.649 12.2791 153.974 11.6047 156.067 11.6047C158.114 11.6047 159.37 12.2791 159.37 13.5581V13.8372L153.323 14.186C150.904 14.3256 149.649 14.6744 148.765 15.2093C147.788 15.814 147.277 16.7442 147.277 17.8605C147.277 20.2326 149.393 21.5349 153.09 21.5349ZM154.788 19.0233C152.951 19.0233 151.928 18.5349 151.928 17.5814C151.928 16.6744 152.649 16.2558 154.765 16.093L159.416 15.7674V16.1628C159.416 17.907 157.532 19.0233 154.788 19.0233Z"
      fill="currentColor"
    />
    <path
      d="M170.664 12.907V9.46512H166.734V21.093H171.222V17.3256C171.222 15.9535 171.595 14.7674 172.455 13.9767C173.129 13.3023 174.199 12.907 175.641 12.907C176.315 12.907 176.874 12.9767 177.502 13.093V9.27907C177.013 9.2093 176.664 9.16279 175.967 9.16279C173.269 9.16279 171.315 10.6279 170.664 12.907Z"
      fill="currentColor"
    />
    <path
      d="M181.881 26C185.532 26 187.625 24.7209 189.323 21.6744L196.067 9.46512H191.253L187.718 16.4651C187.463 17.0233 187.346 17.2558 187.16 17.7209H187.09C186.904 17.2558 186.788 17.0233 186.486 16.3953L182.835 9.46512H177.811L184.881 21.1628L184.811 21.3023C184.183 22.1628 182.602 22.5814 181.16 22.5814C180.509 22.5814 179.742 22.5116 179.09 22.4186V25.7674C179.858 25.9535 181.16 26 181.881 26Z"
      fill="currentColor"
    />
  </Wrapper>
)

export default Logo
