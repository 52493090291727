import React from 'react'
import {
  getImgProps,
  HiLogo,
  HorizontalSpacer,
  SiteHeader as SiteHeaderComponent,
  SkipToContentLink,
} from '@superhi/design'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'

import { buildAccountUrl } from '../../../../routing/utils'
import Logo from '../../../Logo'
import { buildMarketingSiteURL, buildPath } from '../../../../routing'
import { ACCOUNT_URL, DASHBOARD_URL, EDITOR_URL, SITE_URL } from '../../../../config'
import { API } from '../../../../api'

import { ITEMS } from './config'
import { SESSION } from './queries'

const SiteHeader = () => {
  const router = useRouter()
  const { data } = useQuery<API.SiteHeaderComponent_Session>(SESSION)

  const session = data?.session

  const userItems = React.useMemo<
    React.ComponentProps<typeof SiteHeaderComponent>['userItems']
  >(() => {
    const items = [
      {
        display: 'Profile',
        href: `${DASHBOARD_URL}/users/${session?.username}`,
        isExternal: true,
      },
      {
        display: 'Order history',
        href: buildAccountUrl(ACCOUNT_URL!, 'order-history'),
        isExternal: true,
      },
      {
        display: 'Settings',
        href: buildAccountUrl(ACCOUNT_URL!, 'settings'),
        isExternal: true,
      },
    ]

    if (session?.membership) {
      items.splice(1, 0, {
        display: 'Membership',
        href: buildAccountUrl(ACCOUNT_URL!, 'membership'),
        isExternal: true,
      })
    }

    return [
      {
        items: [
          {
            display: 'SuperHi.com',
            href: buildMarketingSiteURL('home'),
            isExternal: true,
          },
          {
            display: 'Student dashboard',
            href: DASHBOARD_URL!,
            isExternal: true,
          },
          {
            display: 'SuperHi Editor',
            href: EDITOR_URL!,
            isExternal: true,
          },
        ],
      },
      { items },
      {
        items: [
          {
            display: 'Log out',
            href: buildAccountUrl(ACCOUNT_URL!, 'logout', {
              redirectUrl: `${SITE_URL}${router.asPath}`,
            }),
            isExternal: true,
            newTab: false,
          },
        ],
      },
    ]
  }, [session, router.asPath])

  const userImage = React.useMemo(
    () =>
      session?.avatar?.url
        ? getImgProps(session.avatar.url, {
            small: 5,
            medium: 5,
            large: 5,
            xlarge: 5,
          })
        : undefined,
    [session?.avatar?.url],
  )

  return (
    <>
      <SkipToContentLink skipTo="main">Skip to Content</SkipToContentLink>
      <SiteHeaderComponent
        items={ITEMS}
        closeOn={router.asPath}
        logo={
          <HorizontalSpacer amount={{ small: 8, medium: 16, large: 16 }} align="center">
            <HiLogo width={{ small: 24, medium: 32, large: 32 }} />
            <Logo height={{ small: 22, medium: 26, large: 26 }} />
          </HorizontalSpacer>
        }
        logoHref={buildPath('home')}
        logInButton={{
          href: buildAccountUrl(ACCOUNT_URL, 'login', {
            redirectUrl: `${SITE_URL}${router.asPath}`,
          }),
          text: 'Log in',
          isExternal: true,
          newTab: false,
        }}
        user={
          session
            ? {
                image: userImage,
                name: session.name || undefined,
                email: session.email,
              }
            : undefined
        }
        userItems={userItems}
        extraActions={
          !session
            ? [
                {
                  display: 'superhi.com',
                  href: buildMarketingSiteURL('home'),
                  isExternal: true,
                },
              ]
            : undefined
        }
      />
    </>
  )
}

export default SiteHeader
