import { compile } from 'path-to-regexp'
import omitBy from 'lodash.omitby'

import ROUTES from './routes'

import querystring from 'querystring'

export const buildPath = (
  routeName: keyof typeof ROUTES,
  options?: {
    params?: object
  },
) => ROUTES[routeName](options?.params)

export const buildQuery = (query: object) =>
  querystring.stringify(omitBy({ ...query }, (v) => v === undefined))

const ACCOUNT_ROUTES = {
  home: '/',
  login: '/login',
  logout: '/logout',
  membership: '/membership',
  'membership-cancel': '/membership/cancel',
  'membership-billing': '/membership/billing',
  'membership-payment': '/membership/payment',
  'reset-password-request': '/reset-password',
  'reset-password-reset': '/reset-password/:passwordToken',
  'order-history': '/order-history',
  settings: '/settings',
  'settings-details': '/settings/details',
  'settings-password': '/settings/password',
  signup: '/signup',
}

export const buildAccountPath = (
  routeName: keyof typeof ACCOUNT_ROUTES,
  options?: {
    params?: object
    query?: object
  },
) => {
  const path = ACCOUNT_ROUTES[routeName]

  if (path) {
    return `${compile(path)(options?.params)}${
      options?.query ? `?${buildQuery(options.query)}` : ''
    }`
  }

  return '/'
}

export const buildAccountUrl = (
  baseUrl: string,
  routeName: Parameters<typeof buildAccountPath>[0],
  options?: {
    params?: Parameters<typeof buildAccountPath>[1]
    redirectUrl?: string
    includeAuthToken?: boolean
  },
) => {
  const url = new URL(`${baseUrl}${buildAccountPath(routeName, options?.params)}`)

  if (options?.redirectUrl) {
    url.searchParams.set('redirectExternal', options.redirectUrl)
  }

  if (options?.includeAuthToken) {
    url.searchParams.set('redirectExternal', String(options.includeAuthToken))
  }

  return url.toString()
}
