import { gql } from '@apollo/client'

export const USER_FRAGMENT = gql`
  fragment SessionSiteHeaderComponent on Session {
    id
    username
    name
    email
    avatar {
      url
    }
    membership {
      id
    }
  }
`

export const SESSION = gql`
  query SiteHeaderComponent_Session {
    session {
      ...SessionSiteHeaderComponent
    }
  }

  ${USER_FRAGMENT}
`
