import { FacebookPixel, Heap, HelpScout } from '../../../services'

export const emailSubmitted = (config: { email: string }) => {
  // Facebook Pixel
  FacebookPixel.track('Lead', { email: config.email })

  // HelpScout
  HelpScout.identify({ name: 'Unknown', email: config.email })

  // Heap
  Heap.addUserProperties({
    email: config.email,
  })
}
