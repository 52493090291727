import React from 'react'
import NextScript from 'next/script'

import { HEAP_APP_ID } from '../../config'

const identify = (config: { id: string }) => {
  window.heap?.identify(config.id)
}

const resetIdentity = () => {
  window.heap?.resetIdentity()
}

const addUserProperties = (data: object) => {
  window.heap?.addUserProperties(data)
}

const clearEventProperties = () => {
  window.heap?.clearEventProperties()
}

const addEventProperties = (data: object) => {
  clearEventProperties()
  window.heap?.addEventProperties(data)
}

const track = (eventName: string, data: object) => {
  window.heap?.track(eventName, data)
}

const Scripts = () => (
  <NextScript
    id="heap-1"
    type="text/javascript"
    dangerouslySetInnerHTML={{
      __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load("${HEAP_APP_ID}");`,
    }}
  />
)

const Heap = {
  Scripts,
  identify,
  resetIdentity,
  addUserProperties,
  clearEventProperties,
  addEventProperties,
  track,
}

export default Heap
