import fetch from 'isomorphic-unfetch'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from 'apollo-link-context'

import authTokenCookie from '../api/auth/cookie'
import { API_URL, API_VERSION } from '../config'

import * as API from './types'

const IS_BROWSER = (process as any).browser
const uri = `${API_URL}/v${API_VERSION}/graphql`

let client: ApolloClient<any> | null = null

const getCookies = () => ({
  [authTokenCookie.name]: authTokenCookie.get(),
})

const authLink = setContext((_, { headers = {} }) => {
  const cookies = getCookies()

  return {
    headers: {
      ...headers,
      ...cookies,
      authorization: cookies[authTokenCookie.name] ? `Bearer ${cookies[authTokenCookie.name]}` : '',
    },
  }
})

const httpLink = createHttpLink({
  uri,
  fetch,
})

if (!IS_BROWSER) {
  ;(global as any).fetch = fetch
}

const createClient = (initialState: any, ssrMode = false) => {
  const cache = new InMemoryCache()

  client = new ApolloClient({
    link: authLink.concat(httpLink as any) as any,
    cache: cache.restore(initialState),
    ssrMode,
    connectToDevTools: IS_BROWSER,
  })

  return client
}

export const initApollo = ({ initialState = {} }: { initialState?: object }) => {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (!IS_BROWSER) {
    return createClient(initialState, true)
  }

  // Reuse client on the client-side
  if (!client) {
    client = createClient(initialState)
  }

  return client
}

export { API }
export default initApollo
