import { theme } from '@superhi/design'
import { ResponsiveBreakpoint } from '@superhi/design/dist/cjs/theme/media'
import styled from 'styled-components'

type WrapperProps = {
  $height?: Record<ResponsiveBreakpoint, number>
}

export const Wrapper = styled.svg.attrs<WrapperProps>({
  width: '197',
  height: '26',
  viewBox: '0 0 192 26',
  fill: 'none',
  xmlns: 'http://www.w3.org/2000/svg',
})<WrapperProps>`
  height: ${({ $height }) => ($height?.small ? $height.small + 'px' : 'auto')};
  width: auto;

  @media ${theme.breakpoints.medium} {
    height: ${({ $height }) => ($height?.medium ? $height.medium + 'px' : 'auto')};
  }

  @media ${theme.breakpoints.large} {
    height: ${({ $height }) => ($height?.large ? $height.large + 'px' : 'auto')};
  }
`
