import { SiteHeader } from '@superhi/design'

import { buildPath } from '../../../../routing/utils'

export const ITEMS: React.ComponentProps<typeof SiteHeader>['items'] = [
  {
    display: 'Topics',
    items: [
      {
        display: 'Code',
        href: buildPath('topics-view', { params: { topicSlug: 'code' } }),
      },
      {
        display: 'Design',
        href: buildPath('topics-view', { params: { topicSlug: 'design' } }),
      },
      {
        display: 'Project Management',
        href: buildPath('topics-view', { params: { topicSlug: 'project-management' } }),
      },
      {
        display: 'Working',
        href: buildPath('topics-view', { params: { topicSlug: 'working' } }),
      },
    ],
  },
  {
    display: 'Guides',
    href: buildPath('guides-index'),
  },
  {
    display: 'Tutorials',
    href: buildPath('tutorials'),
  },
]
