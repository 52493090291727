import React from 'react'
import NextLink from 'next/link'
import { LinkComponentProps } from '@superhi/design/dist/cjs/components/navigation/Link/Link'

const LinkNext: React.FC<LinkComponentProps> = ({ href, ...rest }) => (
  <NextLink href={href} passHref>
    {/* Disable following line since anchor has content when in use */}
    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
    <a {...rest} />
  </NextLink>
)

export default LinkNext
