export const API_URL = process.env.NEXT_PUBLIC_API_URL!
export const API_VERSION = process.env.NEXT_PUBLIC_API_VERSION!

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL! || process.env.NEXT_PUBLIC_VERCEL_URL!
export const MARKETING_SITE_URL = process.env.NEXT_PUBLIC_MARKETING_SITE_URL!
export const ACCOUNT_URL = process.env.NEXT_PUBLIC_ACCOUNT_URL!
export const DASHBOARD_URL = process.env.NEXT_PUBLIC_DASHBOARD_URL!
export const EDITOR_URL = process.env.NEXT_PUBLIC_EDITOR_URL!

export const FACEBOOK_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID!
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID!
export const HEAP_APP_ID = process.env.NEXT_PUBLIC_HEAP_APP_ID!
