import { MARKETING_SITE_URL } from '../../config'

const MARKETING_SITE_ROUTES = {
  home: () => `/`,
  'courses-index': () => `/courses`,
  'courses-view': ({ courseSlug }: { courseSlug: string }) => `/courses/${courseSlug}`,
  editor: () => `/editor`,
  'hall-of-fame-index': () => `/hall-of-fame`,
  'membership-product': () => `/unlimited-membership`,
  teams: () => `/teams`,
  about: () => `/about`,
  'jobs-index': () => `/jobs`,
  'help-center-index': () => `/help-center`,
  'code-of-conduct': () => `/code-of-conduct`,
  'return-policy': () => `/return-policy`,
  'terms-of-use': () => `/terms-of-use`,
  'privacy-policy': () => `/privacy-policy`,
  press: () => `/press`,
  contact: () => `/contact`,
  book: () => `/learn-to-code-now-book`,
  'collections-index': () => `/collections`,
}

function build<T extends keyof typeof MARKETING_SITE_ROUTES>(
  routeName: T,
  options?: { params?: Parameters<(typeof MARKETING_SITE_ROUTES)[T]>[0]; query?: object },
) {
  const url = new URL(`${MARKETING_SITE_URL}${MARKETING_SITE_ROUTES[routeName](options?.params!)}`)

  if (options?.query) {
    Object.entries(options?.query).forEach(([key, value]) => url.searchParams.append(key, value))
  }

  return url.toString()
}

export default build
