import React from 'react'
import { SiteFooter as SiteFooterComponent } from '@superhi/design'

import { ITEMS, SOCIAL_LINKS, BOTTOM_LINKS } from './config'

const SiteFooter = () => (
  <SiteFooterComponent items={ITEMS} socialLinks={SOCIAL_LINKS} bottomLinks={BOTTOM_LINKS} />
)

export default SiteFooter
