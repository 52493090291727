import { anonymous } from './anonymous'
import { loggedIn } from './loggedIn'
import { loggedOut } from './loggedOut'
import { pageViewed } from './pageViewed'
import { emailSubmitted } from './emailSubmitted'

export default {
  anonymous,
  emailSubmitted,
  loggedIn,
  loggedOut,
  pageViewed,
}
