import initApollo, { API } from '../../../api'
import { Heap, HelpScout, GoogleAnalytics } from '../../../services'

import { SESSION } from './queries'

export const loggedIn = async () => {
  const client = initApollo({})

  try {
    const response = await client.query<API.UseEvents_LoggedIn_Session>({
      query: SESSION,
    })

    const session = response.data.session

    if (session) {
      // help scout
      HelpScout.identify({ name: session.name || 'Unknown', email: session.email })

      // heap
      Heap.identify({ id: session.id })
      Heap.addUserProperties({
        email: session.email,
        'User type': session.type,
      })
      Heap.addEventProperties({
        'User type': session.type,
      })

      if (session.team?.id) {
        Heap.addUserProperties({
          'Team ID': session.team?.id,
        })
        Heap.addEventProperties({
          'Team ID': session.team?.id,
        })
      }

      if (session.membership?.status) {
        Heap.addUserProperties({
          'Membership status': session.membership?.status,
        })
        Heap.addEventProperties({
          'Membership status': session.membership?.status,
        })
      }

      // google analytics
      GoogleAnalytics.identify({ id: session.id })
      GoogleAnalytics.setUserProperties({
        userType: session.type,
        teamId: session.team?.id,
        membershipStatus: session.membership?.status,
      })
    }
  } catch (e) {
    console.log(e)
  }
}
