import React from 'react'
import { ThemeProvider, FontHeadComponent } from '@superhi/design'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'

import LinkComponent from '../LinkNext'
import initApollo from '../../api'

import SiteHeader from './components/SiteHeader'
import SiteFooter from './components/SiteFooter'
import Utils from './components/Utils'

const client = initApollo({})

const App = ({ Component, pageProps }: AppProps) => (
  <>
    <Head>
      <FontHeadComponent />
    </Head>

    <ApolloProvider client={client}>
      <Utils />

      <ThemeProvider LinkComponent={LinkComponent}>
        <SiteHeader />

        {/* @ts-ignore */}
        <Component {...pageProps} />

        <SiteFooter />
      </ThemeProvider>
    </ApolloProvider>
  </>
)

export default App
